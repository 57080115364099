<template>
    <b-modal id="doctor-orders-modal" hide-footer title="Thông tin chỉ định của bác sĩ" size="xl">
        <b-container>
            <b-row>
                <b-col>
                    <div class="card card-custom gutter-b">
                        <div class="card-body">
                            <b-overlay :show="loading">
                                <template-table :column="column" :data="customerCenterDoctorOrders" 
                                    :tableAction="false" :selectAction="false"
                                    :paging="false"
                                    :searchAction="false" boundary="window">
                                    <template v-slot:body="{ item, index }">
                                        <td>{{ (index + 1) }}</td>
                                        <td>{{ item.text }}</td>
                                    </template>
                                </template-table>
                            </b-overlay>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
   </b-modal>
</template>
<script>
export default {
    name: "DoctorOrderView",
    props: {
        customerCenterDoctorOrders: {
            type: Array,
            default: () => []
        }
    },
    // watch: {
    //     customerCenterDoctorOrders: {
    //         deep: true,
    //         handler(val) {
    //             this.data = val
    //         }
    //     }
    // },
    data() {
        return {
            loading: false,
            column: [
                {
                    key: 'STT',
                    label: 'STT',
                    sortable: false,
                    style: {
                        width: '50px',
                        'min-width': '50px',
                    },
                },
                {
                    key: 'Name',
                    label: 'Chỉ định',
                    sortable: false,
                }
            ],
        }
    },
}
</script>
<style lang="scss" scoped>
.customer-list-page {
    .customer-list-page__dropdown-form-filter {
        .dropdown-menu {
            .container {
                width: 850px;
            }
        }
    }

    .customer-list-page__body {
        .customer-list-page__body__tabs {
            a.nav-link {
                border: 0;

                &.active {
                    font-weight: 600;
                    color: #01645a !important;
                    border-bottom: 2px solid #01645a;
                }
            }

            li.nav-item {
                a.nav-link:hover {
                    color: #333;
                }
            }
        }
    }

    .customer-list-page__divider {
        border: 1px solid #000000;
        height: 40px;
        transform: rotate(200deg);
    }

    .customer-list-page__block {
        width: 275px;

        .customer-list-page__block__text {
            font-size: 15px;
            font-weight: bold;
        }
    }

}
.card.card-custom > .card-body {
    padding: 0px !important;
}
</style>